<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card class="shiftWarp">
      <v-row class="infoWarp">
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t("messages.factoryName") }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-autocomplete
              v-model="customer"
              :items="listCustomer"
              :item-text="
                (item) =>
                  item.code == null ? item.name : item.code + ' - ' + item.name
              "
              item-value="id"
              :no-data-text="$t('table.messages.no_data')"
              clearable
              single-line
              outlined
            />
          </div>
        </v-col>
        <v-col cols="4">
          <div class="lblText">
            <h3>{{ $t("messages.job") }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="job"
              :disabled="!customer"
              :items="listJobs"
              item-text="name"
              item-value="id"
              name="pa-1"
              multiple
              outlined
              :no-data-text="$t('table.messages.no_data')"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon :color="job.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('messages.all') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span
                  v-if="index === 2"
                  class="grey--text text-caption"
                >(+{{ listJobs.length - 2 }} その他)</span>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="lblText">
            <h3>{{ $t("messages.date") }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <div>
              <v-menu
                v-model="showPicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedStartDateFormatted"
                    name="pa-1"
                    outlined
                    v-bind="attrs"
                    clearable
                    hint="YYYY/MM/DD"
                    append-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    @click:clear="dateStart = ''"
                  />
                </template>
                <v-date-picker
                  v-model="dateStart"
                  :first-day-of-week="0"
                  locale="ja-jn"
                  @input="showPicker = false"
                />
              </v-menu>
            </div>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="inputDisplay"
        >
          <v-btn
            large
            block
            color="forth"
            class="mb-5 pt-0 white--text inputDisplayBtn"
            :disabled="!(customer && job.length > 0 && dateStart)"
            @click="handleSearch"
          >
            <span class="font-weight-bold text-h5">
              {{ $t("messages.display") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <div class="lblText">
            <h3>{{ $t("messages.shiftTime") }}</h3>
          </div>
          <div class="align-self-center mr-9">
            <v-select
              v-model="shift"
              :disabled="!job || job.length === 0"
              :items="listShifts"
              :item-text="getNameShift"
              :item-value="getValueShift"
              name="pa-1"
              outlined
              :no-data-text="$t('table.messages.no_data')"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-col cols="2">
          <v-btn
            block
            large
            color="forth"
            class="mb-5 pt-0 white--text"
            :disabled="!(customer && job.length > 0 && dateStart)"
            @click="toBulkUpdate"
          >
            <span class="font-weight-bold text-h5">
              {{ $t("messages.batchInput") }}</span>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="headers"
            :items="headerOthers"
            :label="$t('messages.selectColumn')"
            multiple
            outlined
            return-object
            :no-data-text="$t('table.messages.no_data')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="grey--text text-caption"
              >(+{{ headers.length - 2 }} その他)</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="items"
            class="elevation-1"
            stle
            :loading-text="$t('table.messages.loading')"
            :loading="loading"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 100],
              showFirstLastPage: false,
              'items-per-page-text': $t('table.messages.items_per_page'),
            }"
            :item-class="itemRowBackground"
          >
            <template v-slot:header.normalWorktime="props">
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template v-slot:header.nightShiftWorktime="props">
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template v-slot:header.otWorktime="props">
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template
              v-if="isOtNightShift"
              v-slot:header.otNightShiftWorktime="props"
            >
              <span class="highlights">
                {{ props.header.text }}
              </span>
            </template>

            <template v-slot:item.manageId="props">
              {{ getValueObject(props.item, "parttimeEmployee.manageId") }}
            </template>

            <template v-slot:item.attendanceConfirmation="props">
              <span
                class="attendanceConfirmation"
                @click="changeIsPresent(props.item)"
              >
                <span v-if="getValueObject(props.item, 'isPresent') !== false">
                  <i class="mdi mdi-radiobox-blank" />
                </span>
                <span v-else>
                  <i class="attendancConfirm mdi mdi-radiobox-marked" />
                </span>
              </span>
            </template>

            <template v-slot:item.name="props">
              {{ getValueObject(props.item, "parttimeEmployee.user.name") }}
            </template>

            <template v-slot:item.position="props">
              {{ getValueObject(props.item, "position") }}
            </template>

            <template v-slot:item.sex="props">
              {{
                props.item.parttimeEmployee.sex
                  ? $t("messages.male")
                  : $t("messages.female")
              }}
            </template>

            <template v-slot:item.job="props">
              <span class="department">
                {{ getValueObject(props.item, "shift.job.name") }}
              </span>
            </template>

            <template v-slot:item.shiftInformation="props">
              <span
                class="shiftInformation"
                @click="
                  $router.push({
                    path: '/shift-information-display/' + getValueObject(props.item, 'periodShiftId')
                  })
                "
              >
                {{ getValueObject(props.item, "periodShiftId") }}
              </span>
            </template>

            <template v-slot:item.actualStartAt="props">
              <cell-edit-time
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :value="getValueObject(props.item, 'actualStartAt')"
                :check-break-time="getValueObject(props.item, 'checkBreakTime', true)"
                prop="actualStartAt"
                :item="props.item"
                @save="save"
                @erorrValidate="showErorr"
              />
            </template>

            <template v-slot:item.actualEndAt="props">
              <cell-edit-time
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :value="getValueObject(props.item, 'actualEndAt')"
                :check-break-time="getValueObject(props.item, 'checkBreakTime', true)"
                prop="actualEndAt"
                :item="props.item"
                @save="save"
                @erorrValidate="showErorr"
              />
            </template>

            <template v-slot:item.normalWorktime="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'normalWorktime')) }}
              </span>
            </template>

            <template v-slot:item.nightShiftWorktime="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'nightShiftWorktime')) }}
              </span>
            </template>

            <template v-slot:item.otWorktime="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'otWorktime')) }}
              </span>
            </template>

            <template
              v-if="isOtNightShift"
              v-slot:item.otNightShiftWorktime="props"
            >
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'otNightShiftWorktime', 0)) }}
              </span>
            </template>

            <template v-slot:item.breaktime="props">
              <cell-edit-time
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :value="getValueObject(props.item, 'breaktime')"
                :check-break-time="getValueObject(props.item, 'checkBreakTime', true)"
                prop="breaktime"
                :item="props.item"
                @save="save"
                @erorrValidate="showErorr"
              />
            </template>

            <template v-slot:item.totalWorkHour="props">
              <span
                v-if="getValueObject(props.item, 'isPresent') !== false"
                :class="{'errorBreakTime':!getValueObject(props.item, 'checkBreakTime', true)}"
              >
                {{ convertTime(getValueObject(props.item, 'totalWorkHour')) }}
              </span>
            </template>

            <template v-slot:item.note="props">
              <v-edit-dialog>
                <span
                  v-if="getValueObject(props.item, 'isPresent') !== false"
                  class="font-weight-bold textName"
                >
                  {{ props.item.note }}
                </span>
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.note"
                    single-line
                    counter
                    @keydown.enter.prevent="saveNote(props.item)"
                    @blur="saveNote(props.item)"
                  />
                </template>
              </v-edit-dialog>
            </template>

            <template
              v-slot:footer.page-text="props"
              class="mr-0"
            >
              {{ $t("table.messages.page") }}
              {{ options.page }}
            </template>

            <template slot="no-data">
              {{ $t("table.messages.no_data") }}
            </template>

            <template v-slot:item.index="props">
              {{ getIndex(props.index) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-row
      v-if="items.length > 0"
      class="d-flex justify-end mt-3"
    >
      <v-col
        cols="2"
        class="d-flex justify-space-between"
      >
        <v-btn
          large
          block
          color="accent"
          type="submit"
          @click="handleAttendenceRecord"
        >
          <span class="text-h5 pr-3 pl-3">{{ $t("messages.save") }}</span>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn
          large
          block
          color="info"
          @click="cancleItems"
        >
          <span class="font-weight-bold text-h5">
            {{ $t("messages.cancel") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import _ from 'lodash'
  import { get } from 'vuex-pathify'
  import CellEdit from '../../components/app/CellEdit'
  import CellEditTime from '../../components/app/CellEditTime'

  export default {
    name: 'TimeSheetDepartment',
    components: {
      CellEdit,
      CellEditTime,
    },
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        radios: true,
        dateStart: '',
        headers: [],
        options: {},
        loading: false,
        items: [],
        headerOthers: [],
        listCustomer: [],
        listShifts: [],
        listJobs: [],
        customer: '',
        isOtNightShift: null,
        division: '',
        shift: null,
        job: [],
        quickValue: '',
        historyItem: '',
        showPicker: false,
      }
    },
    computed: {
      ...get('timesheetDepartment', [
        'list',
        'message',
        'status',
        'error',
        'customers',
        'divisions',
        'shifts',
        'jobs',
      ]),
      customerInfo: get('customer@customerDetail'),
      computedStartDateFormatted () {
        moment.locale('ja')
        return this.dateStart
          ? moment(this.dateStart).format('YYYY年 MMMM Do')
          : ''
      },
      likesAllJob () {
        return this.job.length === this.listJobs.length
      },
      likesSomeJob () {
        return this.job.length > 0 && !this.likesAllJob
      },
      icon () {
        if (this.likesAllJob) return 'mdi-close-box'
        if (this.likesSomeJob) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
    watch: {
      list (value) {
        this.$set(this, 'items', value.data.attendenceRecords.rows)
        this.$set(this, 'total', value.data.attendenceRecords.count)
        this.items = this.convertTimeStringUTC(this.items)
        this.items.forEach(item => {
          this.updateTimeSheetItem(item)
        })
        this.historyItem = _.cloneDeep(this.items)
      },
      customers (value) {
        this.$set(this, 'listCustomer', value.data.customers)
      },
      shifts (value) {
        this.$set(this, 'listShifts', value.data.shifts)
      },
      jobs (value) {
        this.$set(this, 'listJobs', value.data.jobs)
      },
      options: {
        handler () {
          this.getTimeSheets()
        },
        deep: true,
      },
      customerInfo (value) {
        this.isOtNightShift = value.isOtNightShift
        this.getHeaders()
      },
      customer (value) {
        this.job = []
        this.listShifts = []
        this.items = []
        if (value) {
          this.getJobs(value)
          this.$store.dispatch('customer/getCustomer', {
            id: value,
          })
        }
      },
      job (value) {
        this.shift = null
        if (value && value.length > 0) {
          if (this.likesAllJob) {
            value = value.map(el => el.id ? el.id : el)
          }
          this.$store.dispatch('timesheetDepartment/getShift', value)
        } else {
          this.$set(this, 'items', [])
        }
      },
      shift () {
        this.getTimeSheets()
      },
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
          setTimeout(() => {
            this.$router.push('/time-sheet/')
          }, 500)
        }
      },
    },
    created () {},
    mounted () {
      this.getCustomers()
      this.getHeaders()
      this.headerOthers = []
    },
    methods: {
      showErorr (text) {
        this.showSnackBar(text)
      },
      toggle () {
        this.$nextTick(() => {
          if (this.likesAllJob) {
            this.job = []
          } else {
            this.job = this.listJobs.slice()
          }
        })
      },
      getHeaders () {
        if (!this.isOtNightShift) {
          this.headers = [
            {
              text: this.$t('table.headers.index'),
              align: 'center',
              value: 'index',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.manageId'),
              align: 'center',
              value: 'manageId',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.name'),
              align: 'center',
              value: 'name',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.job'),
              align: 'center',
              value: 'job',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.position'),
              align: 'center',
              value: 'position',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.sex'),
              align: 'center',
              value: 'sex',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.shiftReference'),
              align: 'center',
              value: 'shiftInformation',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.attendanceConfirmation'),
              align: 'center',
              value: 'attendanceConfirmation',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualStartAt'),
              align: 'center',
              value: 'actualStartAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualEndAt'),
              align: 'center',
              value: 'actualEndAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.normalWorkTime'),
              align: 'center',
              value: 'normalWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.nightWorkTime'),
              align: 'center',
              value: 'nightShiftWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workOvertime'),
              align: 'center',
              value: 'otWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.breakHour'),
              align: 'center',
              value: 'breaktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workingHour'),
              align: 'center',
              value: 'totalWorkHour',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.remarks'),
              align: 'start',
              value: 'note',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
          ]
        } else {
          this.headers = [
            {
              text: this.$t('table.headers.index'),
              align: 'center',
              value: 'index',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.manageId'),
              align: 'center',
              value: 'manageId',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.name'),
              align: 'center',
              value: 'name',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.job'),
              align: 'center',
              value: 'job',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.position'),
              align: 'center',
              value: 'position',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.sex'),
              align: 'center',
              value: 'sex',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.shiftReference'),
              align: 'center',
              value: 'shiftInformation',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.attendanceConfirmation'),
              align: 'center',
              value: 'attendanceConfirmation',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualStartAt'),
              align: 'center',
              value: 'actualStartAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.actualEndAt'),
              align: 'center',
              value: 'actualEndAt',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.normalWorkTime'),
              align: 'center',
              value: 'normalWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.nightWorkTime'),
              align: 'center',
              value: 'nightShiftWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.workOvertime'),
              align: 'center',
              value: 'otWorktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.nightOtWorkTime'),
              align: 'center',
              value: 'otNightShiftWorktime',
              class: 'font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.breakHour'),
              align: 'center',
              value: 'breaktime',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '4rem',
            },
            {
              text: this.$t('table.headers.workingHour'),
              align: 'center',
              value: 'totalWorkHour',
              class: 'pr-0 pl-2 border-right font-weight-bold',
              sortable: false,
              width: '8rem',
            },
            {
              text: this.$t('table.headers.remarks'),
              align: 'start',
              value: 'note',
              class: 'pr-0 pl-2 font-weight-bold',
              sortable: false,
              width: '8rem',
            },
          ]
        }
      },
      changeIsPresent (item) {
        if (item.isPresent === null) {
          item.isPresent = false
        } else {
          item.isPresent = !item.isPresent
        }
      },
      checkShiftDay (time) {
        return (time > 5 && time < 22) || (time > 29 && time < 46)
      },
      updateTimeSheetItem (item) {
        let startTime = this.converNumber(item.actualStartAt)
        let endTime = this.converNumber(item.actualEndAt)
        let workTime = 0
        let otTime = 0
        let otNightShift = 0

        workTime = endTime - startTime

        if (parseFloat(startTime) > parseFloat(endTime)) {
          workTime = parseFloat(endTime) + 24 - startTime
        }

        if (parseFloat(startTime) > 24) {
          startTime = parseFloat(startTime) - 24
        }

        if (parseFloat(endTime) > 24) {
          endTime = parseFloat(endTime) - 24
        }

        if (workTime > 8) {
          otTime = workTime - 8
          workTime = 8
        }

        if (this.checkShiftDay(parseFloat(startTime)) && !this.checkShiftDay(parseFloat(startTime) + workTime)) {
          item.normalWorktime = 22 - startTime
          item.nightShiftWorktime = workTime - item.normalWorktime
        }

        if (!this.checkShiftDay(parseFloat(startTime)) && this.checkShiftDay(parseFloat(startTime) + workTime)) {
          if (startTime <= 5) {
            item.nightShiftWorktime = 5 - startTime
            item.normalWorktime = workTime - item.nightShiftWorktime
          }
          if (startTime >= 22) {
            item.nightShiftWorktime = 29 - startTime
            item.normalWorktime = workTime - item.nightShiftWorktime
          }
        }

        if (this.checkShiftDay(parseFloat(startTime)) && this.checkShiftDay(parseFloat(startTime) + workTime)) {
          if (parseFloat(startTime) < 22 && this.converNumber(item.actualEndAt) > 29) {
            item.normalWorktime = 22 - parseFloat(startTime) + parseFloat(this.converNumber(item.actualEndAt)) - 29
            item.nightShiftWorktime = 7
          } else {
            item.normalWorktime = workTime
            item.nightShiftWorktime = 0
          }
        }

        if (!this.checkShiftDay(parseFloat(startTime)) && !this.checkShiftDay(parseFloat(startTime) + workTime)) {
          item.nightShiftWorktime = workTime
          item.normalWorktime = 0
        }

        const worktimeType = this.getValueObject(item, 'shift.worktimeType')

        item.checkBreakTime = true

        if (worktimeType) {
          if (item.breaktime > item.normalWorktime) {
            item.checkBreakTime = false
            this.showSnackBar(this.$t('error.breakTime'))
          }
          item.normalWorktime = item.normalWorktime - item.breaktime
        }

        if (!worktimeType) {
          if (item.breaktime > item.nightShiftWorktime) {
            item.checkBreakTime = false
            this.showSnackBar(this.$t('error.breakTime'))
          }
          item.nightShiftWorktime = item.nightShiftWorktime - item.breaktime
        }
        const breaktime = item.breaktime ? parseFloat(item.breaktime) : 0
        if (otTime > 0 && this.isOtNightShift) {
          const start = parseFloat(this.converNumber(item.actualStartAt))
          const end = parseFloat(this.converNumber(item.actualEndAt))
          if (start < 5 || (start >= 22 && start < 29) || start >= 46) {
            item.normalWorktime += breaktime
          } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
            if (((start + 8 >= 22 && start + 8 < 29) || (start + 8) >= 46) && (item.nightShiftWorktime + breaktime) < 7) {
              item.nightShiftWorktime += breaktime
            } else {
              item.normalWorktime += breaktime
            }
          }
          const time = start + item.normalWorktime + item.nightShiftWorktime + breaktime
          if (end > 5 && end <= 22) {
            otNightShift = 0
          } else if (end > 22 && end <= 29) {
            if (time <= 22) {
              otNightShift = end - 22
            } else {
              otNightShift = end - time
            }
          } else if (end > 29 && end <= 46) {
            if (time <= 22) {
              otNightShift = end - 22 - (end - 29)
            } else {
              otNightShift = end - time - (end - 29)
            }
          } else if (end > 46) {
            if (time <= 22) {
              otNightShift = end - 22 - (end - 29) + (end - 46)
            } else {
              otNightShift = end - time - (end - 29) + (end - 46)
            }
          }
          otTime -= otNightShift + breaktime
          if (otTime < 0 && !otNightShift) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otTime = 0
            } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
              item.normalWorktime += otTime
              otTime = 0
            }
          } else if (otTime < 0 && otNightShift) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otNightShift += otTime
              otTime = 0
            } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
              if (item.nightShiftWorktime > 0) {
                otNightShift += otTime
                otTime = 0
              } else {
                otNightShift += otTime
                otTime = 0
              }
            }
          } else if (otNightShift < 0) {
            otTime += otNightShift
            otNightShift = 0
          }
        } else if (otTime > 0 && !this.isOtNightShift) {
          const start = parseFloat(this.converNumber(item.actualStartAt))
          if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
            item.normalWorktime += breaktime
          } else if ((start >= 5 && start < 22) || (start >= 29 && start < 46)) {
            if (((start + 8 >= 22 && start + 8 < 29) || (start + 8) >= 46) && (item.nightShiftWorktime + breaktime) < 7) {
              item.nightShiftWorktime += breaktime
            } else {
              item.normalWorktime += breaktime
            }
          }
          otTime -= breaktime
          if (otTime < 0) {
            if ((start < 5) || (start >= 22 && start < 29) || (start >= 46)) {
              item.nightShiftWorktime += otTime
              otTime = 0
            } else if ((start >= 5 && start <= 22) || (start >= 29 && start < 46)) {
              item.normalWorktime += otTime
              otTime = 0
            }
          }
        } else {
          otNightShift = 0
        }
        item.otWorktime = otTime
        item.otNightShiftWorktime = otNightShift
        item.totalWorkHour = parseFloat(item.normalWorktime) + parseFloat(item.otWorktime) + parseFloat(item.nightShiftWorktime) + parseFloat(item.otNightShiftWorktime)
        item.totalWorkHour = item.totalWorkHour ? parseFloat(item.totalWorkHour).toFixed(2) : 0
        item.normalWorktime = parseFloat(item.normalWorktime).toFixed(2)
        item.nightShiftWorktime = item.nightShiftWorktime ? parseFloat(item.nightShiftWorktime).toFixed(2) : 0
        item.otWorktime = parseFloat(item.otWorktime).toFixed(2)
        item.otNightShiftWorktime = (this.isOtNightShift && item.otNightShiftWorktime) ? parseFloat(item.otNightShiftWorktime).toFixed(2) : 0
      },
      converNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = (parseInt(str.slice(3, 5)) / 60)
        return parseFloat(timeStart + timeEnd).toFixed(2)
      },
      convertTime (float) {
        let hour = parseInt(float)
        let minute = String(Math.round((float - parseInt(float)) * 60))

        if (hour.length === 1) {
          hour = '0' + hour
        }

        if (minute.length === 1) {
          minute = '0' + minute
        }

        return hour + ':' + minute
      },
      handleSearch () {
        this.getTimeSheets()
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getValueObject (item, path, d = '') {
        return _.get(item, path, d)
      },
      saveNote (item) {
        const payload = {
          value: event.target.value,
          item: item,
          prop: 'note',
        }
        this.save(payload)
      },
      save (payload) {
        if (payload.prop === 'actualStartAt') {
          const timeFirstStart = parseInt(payload.value.slice(0, 2))
          const timeLastStart = parseInt(payload.value.slice(3, 5))
          const timeFirstEnd = parseInt(payload.item.actualEndAt.slice(0, 2))
          const timeLastEnd = parseInt(payload.item.actualEndAt.slice(3, 5))
          if (timeFirstEnd === 0 && timeLastEnd === 0) {
            payload.item.actualEndAt = ''
          } else {
            if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
              return
            }
          }
        } else if (payload.prop === 'actualEndAt') {
          const timeFirstStart = parseInt(payload.item.actualStartAt.slice(0, 2))
          const timeLastStart = parseInt(payload.item.actualStartAt.slice(3, 5))
          const timeFirstEnd = parseInt(payload.value.slice(0, 2))
          const timeLastEnd = parseInt(payload.value.slice(3, 5))
          if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
            return
          }
        }
        payload.item[payload.prop] = _.cloneDeep(payload.value)
        this.updateTimeSheetItem(payload.item)
      },
      checkActualAt (timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd) {
        let error = false
        if (timeFirstStart > timeFirstEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        } else if (timeFirstStart === timeFirstEnd && timeLastStart > timeLastEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        }
        return error
      },
      checkBreakTime (items) {
        let isCheck = true

        items.forEach(item => {
          if (parseFloat(item.normalWorktime) < 0 || parseFloat(item.nightShiftWorktime) < 0) {
            this.showSnackBar(this.$t('error.breakTime'))
            isCheck = false
          }
        })

        return isCheck
      },
      checkActualTime (items) {
        let isCheck = true
        items.forEach(item => {
          if (!item.actualStartAt) {
            isCheck = false
          }

          if (!item.actualEndAt) {
            isCheck = false
          }
        })
        return isCheck
      },
      handleAttendenceRecord () {
        if (!this.checkActualTime(this.items)) {
          this.showSnackBar(this.$t('error.bad_input'))
          return
        }
        if (this.checkBreakTime(this.items)) {
          const payload = {
            customerId: this.customer,
            workdate: this.dateStart,
            records: this.convertTimeStamp(this.items),
          }
          this.$store.dispatch(
            'timesheetDepartment/handleAttendenceRecord',
            payload,
          )
        }
      },
      getValue (value) {
        this.quickValue = value
      },
      fomatDateTime (string) {
        return moment(string).format('HH:mm')
      },
      getNameShift (shift) {
        if (_.get(shift, 'shiftStartAt', '') === '') {
          return '00:00 ~ 00:00'
        }
        return (
          _.get(shift, 'shiftStartAt', '00:00') +
          ' ~ ' +
          _.get(shift, 'shiftEndAt', '00:00')
        )
      },
      getValueShift (shift) {
        return _.get(shift, 'id', null)
      },
      getCustomers () {
        this.$store.dispatch('timesheetDepartment/getCustomers')
      },
      getTimeSheets (payload = {}) {
        const tmpOptions = { ...payload, ...this.options }
        tmpOptions.getBy = 'job'

        if (this.customer) {
          tmpOptions.customerId = this.customer
        }

        if (this.job.length > 0) {
          if (this.likesAllJob) {
            tmpOptions.jobId = this.job.map(el => el.id ? el.id : el)
          } else {
            tmpOptions.jobId = this.job
          }
        }

        if (this.shift) {
          tmpOptions.shiftId = this.shift
        }

        if (this.dateStart) {
          tmpOptions.startAt = this.dateStart
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        delete tmpOptions.isTrusted

        if (this.customer && this.job.length > 0 && this.dateStart) {
          this.$store.dispatch('timesheetDepartment/getTimeSheets', tmpOptions)
        }
      },
      getJobs (id) {
        this.listJobs = []
        this.$store.dispatch('timesheetDepartment/getJobs', id)
      },
      cancleItems () {
        this.items = _.cloneDeep(this.historyItem)
      },
      toBulkUpdate () {
        const query = {}
        if (this.customer) {
          query.customerId = this.customer
        }

        if (this.job) {
          if (this.likesAllJob) {
            query.jobId = this.job.map(el => el.id ? el.id : el)
          } else {
            query.jobId = this.job
          }
        }

        if (this.shift) {
          query.shiftId = this.shift
        }

        if (this.dateStart) {
          query.startAt = this.dateStart
        }

        if (this.customer && this.job.length > 0 && this.dateStart) {
          this.$router.push({
            path: '/time-sheet-department/bulk-update',
            query,
          })
        } else {
          this.showSnackBar('messages.jobNotSelected')
        }
      },
      convertTimeStringUTC (items) {
        this.items.forEach((item) => {
          if (
            moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = moment
              .utc(new Date(item.actualEndAt))
              .format('HH:mm')
          } else if (
            moment.utc(new Date(item.plannedStartAt)).add(2, 'days').format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualEndAt)).format('YYYY-MM-DD')
          ) {
            item.actualEndAt = '48:00'
          } else {
            item.actualEndAt = this.handleCalculatorTime(moment.utc(new Date(item.actualEndAt)).format('HH:mm'))
          }
          if (
            moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualStartAt)).format('YYYY-MM-DD')
          ) {
            item.actualStartAt = moment
              .utc(new Date(item.actualStartAt))
              .format('HH:mm')
          } else if (
            moment.utc(new Date(item.plannedStartAt)).add(2, 'days').format('YYYY-MM-DD') ===
            moment.utc(new Date(item.actualStartAt)).format('YYYY-MM-DD')
          ) {
            item.actualStartAt = '48:00'
          } else {
            item.actualStartAt = this.handleCalculatorTime(moment.utc(new Date(item.actualStartAt)).format('HH:mm'))
          }
        })
        return _.cloneDeep(items)
      },
      handleCheckTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) - 24)
        if (setTime.length === 1) {
          setTime = '0' + setTime + ':'
        } else if (setTime.length === 2) {
          setTime += ':'
        }
        setTime += String(time.slice(3, 5))
        return setTime
      },
      handleCalculatorTime (time) {
        let setTime = String(parseInt(time.slice(0, 2)) + 24)
        setTime += ':' + String(time.slice(3, 5))
        return setTime
      },
      convertTimeStamp (items) {
        this.items.forEach((item) => {
          if (item.isPresent || item.isPresent === null) {
            let startAt = _.cloneDeep(item.actualStartAt)
            let endAt = _.cloneDeep(item.actualEndAt)
            let plannedStartAt = item.plannedStartAt
            let plannedEndAt = item.plannedStartAt

            if (parseInt(startAt.slice(0, 2)) >= 24) {
              startAt = this.handleCheckTime(startAt)
              plannedStartAt = moment.utc(new Date(item.plannedStartAt)).add(1, 'days').format('YYYY-MM-DD')
            }

            if (parseInt(endAt.slice(0, 2)) >= 24) {
              endAt = this.handleCheckTime(endAt)
              plannedEndAt = moment.utc(new Date(item.plannedStartAt)).add(1, 'days').format('YYYY-MM-DD')
            }

            const endTime =
              moment.utc(new Date(plannedEndAt)).format('YYYY-MM-DD') +
              'T' +
              endAt

            const startTime =
              moment.utc(new Date(plannedStartAt)).format('YYYY-MM-DD') +
              'T' +
              startAt

            item.actualStartAt = startTime

            if (moment.utc(startTime).unix() > moment.utc(endTime).unix()) {
              item.actualEndAt =
                moment
                  .utc(new Date(plannedEndAt))
                  .add(1, 'day')
                  .format('YYYY-MM-DD') +
                'T' +
                endAt
            } else {
              item.actualEndAt = endTime
            }
          } else {
            item.actualEndAt =
              moment.utc(new Date(item.plannedStartAt)).format('YYYY-MM-DD') +
              'T00:00:00'
            item.actualStartAt =
              moment.utc(new Date(item.plannedEndAt)).format('YYYY-MM-DD') +
              'T00:00:00'
            item.normalWorktime = 0
            item.nightShiftWorktime = 0
            item.otWorktime = 0
            item.otNightShiftWorktime = 0
            item.breaktime = 0
            item.totalWorkHour = 0
          }
        })
        return _.cloneDeep(items)
      },
      convertTimeString (items) {
        this.items.forEach((item) => {
          item.actualEndAt = moment(new Date(item.actualEndAt)).format('HH:mm')
          item.actualStartAt = moment(new Date(item.actualStartAt)).format(
            'HH:mm',
          )
        })
        return _.cloneDeep(items)
      },
      itemRowBackground (item) {
        if (item.isPresent === false) {
          return 'disableShift'
        }
        return ''
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
    },
  }
</script>

<style scoped lang="sass">
.border-right
  border-right: thin solid rgba(0, 0, 0, 0.12)!important

.shiftWarp
  margin-top: 15px
  padding: 0 50px

  .infoWarp
    padding-top: 20px

  .lblText
    margin-top: 10px

.dateRadio
  display: flex
  align-items: center

  h3
    margin-right: 20px
.dateWarp
  display: flex
  justify-content: space-between

  .icon-fall
    font-size: 20px
    font-weight: bold
    margin-top: 10px
.btnAddHour
  width: 41px
  min-width: 41px!important
.inputDisplay
  display: flex
  align-items: center
  .inputDisplayBtn
    margin: 0px!important
.attendancConfirm
  color: #E43E08
.highlights
  color: #E43E08
.shiftInformation, .department
  color: #4262FF
  font-weight: 600
.shiftAt
  margin-left: 0px!important
.editShiftAt
  padding: 0px!important
.attendanceConfirmation
  cursor: pointer
.shiftInformation
  cursor: pointer
.errorBreakTime
  color: #E43E08
</style>
